<template>
<validation-observer v-slot="{ invalid }" slim>
    <div class="row addressRow">
      <validation-provider name="address" rules="required|max:50" v-slot="{ errors }" slim>
        <div class="form-group">
          <label for="address">{{ $t('order.delivery.address') }}*</label>
          <canada-post-search
            v-model.lazy="billingAddress.address"
            :addressCompleteSettings="addressCompleteSettings"
            :initialAddress="billingAddress.address"
            :invalid="errors.length > 0"
            searchFieldId="billingAddress"
            @updateAddress="updateAddress($event)"
          />
        </div>
      </validation-provider>
      <validation-provider name="city" rules="required|max:50" v-slot="{ errors }" slim>
        <div class="form-group">
          <label for="city">{{ $t('order.delivery.city') }}*</label>
          <input type="text" id="city" v-model.lazy="billingAddress.city" :class="{ invalid: errors.length }">
        </div>
      </validation-provider>
      <validation-provider name="postalCode" rules="required|max:6|postalCode" v-slot="{ errors }" slim>
        <div class="form-group postalCodeGroup">
          <label for="postalCode">{{ $t('order.delivery.postalCode') }}*</label>
          <the-mask id="postalCode" type="text" mask="A#A#A#" v-model.lazy="billingAddress.postalCode" :class="{ invalid: errors.length }"/>
        </div>
      </validation-provider>
    </div>
  </validation-observer>
</template>

<script>
import CanadaPostSearch from '@/components/CanadaPostSearch'
import { TheMask } from 'vue-the-mask'
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    CanadaPostSearch,
    TheMask,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    addressCompleteSettings: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      billingAddress: {
        address: '',
        city: '',
        postalCode: ''
      }
    }
  },
  computed: {
    isValid() {
      return this.billingAddress.address && this.billingAddress.city && this.billingAddress.postalCode
    }
  },
  methods: {
    ...mapActions('cartModule', ['setBillingAddress']),
    updateAddress(address) {
      this.billingAddress = { address: address.Line1, city: address.City, postalCode: address.PostalCode }
    },
    updateUserAddress() {
      const storedAddress = this.$store.state.cartModule.billingAddress
      let addressId = null
      let existingAddress = null

      if (this.user) {
        addressId = this.user.defaultPaymentAddressID !== 0 ? this.user.defaultPaymentAddressID : this.user.defaultShippingAddressID
        existingAddress = this.user.addresses.find(x => x.addressID === addressId)
      };
      
      this.billingAddress.address = storedAddress && storedAddress.address ? storedAddress.address : (existingAddress ? existingAddress.addressLine : undefined)
      this.billingAddress.city = storedAddress && storedAddress.city ? storedAddress.city : (existingAddress ? existingAddress.city : undefined)
      this.billingAddress.postalCode = storedAddress && storedAddress.postalCode ? storedAddress.postalCode : (existingAddress ? existingAddress.postalCode : undefined)
    }
  },
  created() {
    this.updateUserAddress()
  },
  watch: {
    billingAddress: {
      deep: true,
      handler: function(value) {
        this.setBillingAddress(value)
      }
    }
  }
}
</script>
