var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "userAccount" },
    [
      _vm.isLoadingUser
        ? _c("div", { staticClass: "loading" }, [
            _c("img", { attrs: { src: "/images/triangle-loading.gif" } }),
          ])
        : !_vm.user
        ? [
            _c("span", {
              domProps: {
                innerHTML: _vm._s(_vm.$t("users.messages.getUserError")),
              },
            }),
          ]
        : [
            _c(
              "section",
              { staticClass: "userInfoSection" },
              [
                _c("h2", { staticClass: "userInfoSection-heading" }, [
                  _vm._v(_vm._s(_vm.$t("users.titles.userInfo"))),
                ]),
                [
                  _c("user-info", { attrs: { user: _vm.user } }),
                  _c("hr"),
                  _vm.user.accountProvider == null
                    ? _c("email-info", { attrs: { user: _vm.user } })
                    : _vm._e(),
                  _vm.user.accountProvider == null ? _c("hr") : _vm._e(),
                  _vm.user.accountProvider == null
                    ? _c("password-info", {
                        attrs: {
                          user: _vm.user,
                          "connection-url": _vm.connectionUrl,
                        },
                      })
                    : _vm._e(),
                  _vm.user.accountProvider == null ? _c("hr") : _vm._e(),
                  _vm.userPaymentIsEnabled
                    ? _c("payment-methods-info", {
                        attrs: {
                          "bambora-configuration": _vm.bamboraConfiguration,
                          user: _vm.user,
                        },
                      })
                    : _vm._e(),
                  _vm.userPaymentIsEnabled ? _c("hr") : _vm._e(),
                  _vm.userPaymentIsEnabled
                    ? _c("billing-info", { attrs: { user: _vm.user } })
                    : _vm._e(),
                  _vm.userPaymentIsEnabled ? _c("hr") : _vm._e(),
                  _c("default-branch-info", {
                    attrs: { user: _vm.user, branches: _vm.branches },
                  }),
                  _c("hr"),
                  _c("shipping-info", { attrs: { user: _vm.user } }),
                ],
              ],
              2
            ),
            _c(
              "section",
              { staticClass: "orderSummariesSection" },
              [
                _c("h2", { ref: "ordersSection" }, [
                  _vm._v(_vm._s(_vm.$t("account.purchaseTitle"))),
                ]),
                _c("order-summaries", {
                  attrs: { orderSummaries: _vm.user.orders },
                }),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }