<template>
  <button :class="`addFavorite-${this.product.identifier}`" class="addFavorite">
    <img v-if="memberId" src="@/assets/svg/heart.svg" @click="addFavorite">
  </button>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    memberId: {
      type: Number,
      default: null
    }
  },
  methods: {
    async addFavorite() {
      const model = {
        memberID: this.memberId,
        productID: this.product.identifier
      }
        const addButton = document.querySelector(`.addFavorite-${this.product.identifier}`)
        const deleteButton = document.querySelector(`.deleteFavorite-${this.product.identifier}`)
      try {
        addButton.classList.toggle('hidden')
        deleteButton.classList.toggle('hidden')
        await this.$axios.post('/Umbraco/Api/Favorites/AddFavorite', model)
      }
      catch (ex) {
        addButton.classList.toggle('hidden')
        deleteButton.classList.toggle('hidden')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>