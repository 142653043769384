var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        staticClass: "loadingProduct",
      },
      [_c("img", { attrs: { src: "/images/triangle-loading.gif" } })]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              !_vm.isLoading &&
              _vm.itemsContainer.items &&
              _vm.itemsContainer.items.length > 0,
            expression:
              "!isLoading && itemsContainer.items && itemsContainer.items.length > 0",
          },
        ],
        ref: "itemsProducts",
        staticClass: "itemsContainer",
      },
      [
        _vm.hasCta
          ? [
              _c(
                "div",
                { staticClass: "first" },
                [
                  _vm._l(
                    _vm.take(_vm.itemsContainer.items, 8),
                    function (item) {
                      return [
                        _vm._t("default", null, {
                          member: _vm.memberId,
                          item: item,
                          isFavorite: _vm.favorites.some((x) =>
                            x.productID.includes(item.identifier) ? true : false
                          ),
                        }),
                      ]
                    }
                  ),
                ],
                2
              ),
              _vm._t("cta"),
              _c(
                "div",
                { staticClass: "second" },
                [
                  _vm._l(
                    _vm.skip(_vm.itemsContainer.items, 8),
                    function (item) {
                      return [
                        _vm._t("default", null, {
                          member: _vm.memberId,
                          item: item,
                          isFavorite: _vm.favorites.some((x) =>
                            x.productID.includes(item.identifier) ? true : false
                          ),
                        }),
                      ]
                    }
                  ),
                ],
                2
              ),
            ]
          : _vm._l(_vm.itemsContainer.items, function (item) {
              return [
                _vm._t("default", null, {
                  member: _vm.memberId,
                  item: item,
                  isFavorite: _vm.favorites.some((x) =>
                    x.productID.includes(item.identifier) ? true : false
                  ),
                }),
              ]
            }),
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              !_vm.isLoading &&
              _vm.itemsContainer.items &&
              _vm.itemsContainer.items.length === 0,
            expression:
              "!isLoading && itemsContainer.items && itemsContainer.items.length === 0",
          },
        ],
      },
      [_vm._v(" " + _vm._s(_vm.$t("noResults")) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }