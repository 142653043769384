<!-- eslint-disable -->
<template>
  <div class="pagination">
    <div>
      <button :disabled="currentPage <= 1" @click="firstPage" class="firstPage">
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" fill="#E31F28" />
        </svg>
      </button>
      <button :disabled="currentPage <= 1" @click="previousPage" class="previousPage">
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" fill="#E31F28" />
        </svg>
        {{ $t('pagination.previous') }}
      </button>
      <button v-for="i in pageRange" :key="i" @click="goToPage(i)" :class="{selected: currentPage === i}">{{i}}</button>
      <button :disabled="currentPage >= pageCount" @click="nextPage" class="nextPage">
        {{ $t('pagination.next') }}
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" fill="#E31F28" />
        </svg>
      </button>
      <button :disabled="currentPage >= pageCount" @click="lastPage" class="lastPage">
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" fill="#E31F28" />
        </svg>
      </button>
    </div>
  </div>
</template>
<!-- eslint-enable -->

<script>
import hashMixins from '../../hashMixins';

export default {
  mixins: [hashMixins],
  props: {
    itemsContainer: {
      type: Object,
      required: true
    },
    maxNumberOfPages: {
      type: Number,
      default: 5
    },
    scrollTopElement: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentPage: 0
    };
  },
  computed: {
    numberOfItems() {
      return this.itemsContainer.total;
    },
    itemsPerPage() {
      return this.itemsContainer.selectedFacets.count || 16;
    },
    pageCount() {
      return !this.numberOfItems ? 1 : Math.ceil(this.numberOfItems / this.itemsPerPage);
    },
    index() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    pageRange() {
      let start = this.currentPage - Math.floor((this.maxNumberOfPages - 1) / 2);
      let end = Math.min(this.pageCount, this.currentPage + Math.ceil((this.maxNumberOfPages - 1) / 2));

      start = Math.max(1, Math.min(end - this.maxNumberOfPages + 1, start));
      end = Math.min(this.pageCount, Math.max(this.maxNumberOfPages - start + 1, end));

      return Array.from({ length: end - start + 1 }, (_, i) => i + start);
    }
  },
  mounted() {
    this.navigate();
    this.$root.$on('resetPage', this.firstPage);
    window.addEventListener('hashchange', this.navigate);
  },
  beforeDestroy() {
    this.$root.$off('resetPage', this.firstPage);
  },
  methods: {
    goToPage(page, sendScrollEvent = true, count = 0, index = 0) {
      this.currentPage = Math.floor(page);
      count = count || this.itemsPerPage;
      index = index || this.index;
      this.scrollToTop();
      if (index >= 0) {
        this.itemsContainer.selectedFacets = {
          ...this.itemsContainer.selectedFacets,
          count,
          index
        };
      } else {
        /* eslint-disable-next-line no-unused-vars */
        const { index, ...filteredFacets } = this.itemsContainer.selectedFacets;
        this.itemsContainer.selectedFacets = filteredFacets;
      }

      if (sendScrollEvent) {
        this.$root.$emit('scroll');
      }
    },
    navigate() {
      const hashParams = this.getHashParams();
      const count = hashParams.count > 0 ? hashParams.count : this.itemsPerPage;
      const index = hashParams.index > 0 ? hashParams.index : 0;
      this.goToPage(index / count + 1, false, count, index);
    },
    nextPage() {
      this.goToPage(Math.min(this.pageCount, this.currentPage + 1));
    },
    previousPage() {
      this.goToPage(Math.max(1, this.currentPage - 1));
    },
    firstPage() {
      this.goToPage(1);
    },
    lastPage() {
      this.goToPage(this.pageCount);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>
