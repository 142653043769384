var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-observer", {
    attrs: { slim: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ invalid }) {
          return [
            _c(
              "div",
              { staticClass: "row addressRow" },
              [
                _c("validation-provider", {
                  attrs: {
                    name: "address",
                    rules: "required|max:50",
                    slim: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "address" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("order.delivery.address")) +
                                      "*"
                                  ),
                                ]),
                                _c("canada-post-search", {
                                  attrs: {
                                    addressCompleteSettings:
                                      _vm.addressCompleteSettings,
                                    initialAddress: _vm.billingAddress.address,
                                    invalid: errors.length > 0,
                                    searchFieldId: "billingAddress",
                                  },
                                  on: {
                                    updateAddress: function ($event) {
                                      return _vm.updateAddress($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.billingAddress.address,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingAddress,
                                        "address",
                                        $$v
                                      )
                                    },
                                    expression: "billingAddress.address",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("validation-provider", {
                  attrs: { name: "city", rules: "required|max:50", slim: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "city" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("order.delivery.city")) + "*"
                                ),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.lazy",
                                    value: _vm.billingAddress.city,
                                    expression: "billingAddress.city",
                                    modifiers: { lazy: true },
                                  },
                                ],
                                class: { invalid: errors.length },
                                attrs: { type: "text", id: "city" },
                                domProps: { value: _vm.billingAddress.city },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.billingAddress,
                                      "city",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("validation-provider", {
                  attrs: {
                    name: "postalCode",
                    rules: "required|max:6|postalCode",
                    slim: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "form-group postalCodeGroup" },
                              [
                                _c("label", { attrs: { for: "postalCode" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("order.delivery.postalCode")
                                    ) + "*"
                                  ),
                                ]),
                                _c("the-mask", {
                                  class: { invalid: errors.length },
                                  attrs: {
                                    id: "postalCode",
                                    type: "text",
                                    mask: "A#A#A#",
                                  },
                                  model: {
                                    value: _vm.billingAddress.postalCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billingAddress,
                                        "postalCode",
                                        $$v
                                      )
                                    },
                                    expression: "billingAddress.postalCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }