<template>
  <div class="orderDetailsSection">
    <div class="bgWhite">
      <div class="orderDetails">
        <div class="title">
          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" fill="#E31F28" />
          </svg>
          {{ title || $t('cart.title') }}
        </div>
        <div class="cartTable">
          <template v-if="!this.$root.getFeatureFlag('hidePrices')">
            <div class="bottom">
              <div class="cartRow">
                <div>{{ $t('cart.subTotal') }}</div>
                <div>
                  <!-- <span>{{ subTotal !== null ? $root.formatPrice(subTotal - (shipping || 0)) : '--' }}</span> -->
                  <span v-if="subTotal !== null && shipping === null">{{ $root.formatPrice(subTotal) }}</span>
                  <span v-if="subTotal !== null && shipping !== null && shippingExtra !== null">{{ $root.formatPrice(subTotal - shipping - shippingExtra) }}</span>
                  <span v-else-if="subTotal !== null && shipping !== null">{{ $root.formatPrice(subTotal - shipping) }}</span>                  
                  <span v-if="subTotal === null && shipping === null">--</span>
                </div>
              </div>
              <div class="cartRow" v-if="shipping !== null">
                <div>{{ $t('cart.shipping') }}</div>
                <div>
                  <span>{{ $root.formatPrice(shipping) }}</span>
                </div>
              </div>
              <div class="cartRow" v-if="shippingExtra !== null && shippingExtra > 0">
                <div>{{ $t('cart.shippingExtra') }}</div>
                <div>
                  <span>{{ $root.formatPrice(shippingExtra) }}</span>
                </div>
              </div>
              <hr>
              <div class="cartRow">
                <div>{{ $t('cart.taxes') }}</div>
                <div>
                  <span v-if="gst !== null">
                    {{ $t('cart.gst') }} {{ $root.formatPrice(gst) }}
                  </span>
                  <span v-else>--</span>
                </div>
              </div>
              <div class="cartRow">
                <div></div>
                <div>
                  <span v-if="qst !== null">
                    {{ $t('cart.qst') }} {{ $root.formatPrice(qst) }}
                  </span>
                  <span v-else>--</span>
                </div>
              </div>
              <hr>
              <div class="cartRow orderTotal">
                <div class="text">{{ $t('cart.orderTotal') }} :</div>
                <div class="price">
                  <span v-if="total !== null">{{ $root.formatPrice(total) }}</span>
                  <span v-else>--</span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <slot name="bottom" />
      <slot name="legalnotices" />
    </div>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';

export default {
  components: {
    TheMask
  },
  props: {
    gst: {
      validator: prop => ['number', 'string'].includes(typeof prop) || prop === null,
      required: true
    },
    qst: {
      validator: prop => ['number', 'string'].includes(typeof prop) || prop === null,
      required: true
    },
    shipping: {
      validator: prop => ['number', 'string'].includes(typeof prop) || prop === null,
      required: false
    },
    shippingExtra: {
      validator: prop => ['number', 'string'].includes(typeof prop) || prop === null,
      required: false
    },
    subTotal: {
      validator: prop => typeof prop === 'number' || prop === null,
      required: true
    },
    title: {
      type: String
    },
    total: {
      validator: prop => typeof prop === 'number' || prop === null,
      required: true
    }
  }
};
</script>
