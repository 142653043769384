var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "registerForm-wrapper" },
    [
      _c(
        "validation-observer",
        {
          ref: "registerForm",
          staticClass: "registerForm accountForm",
          attrs: { tag: "form", novalidate: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("validation-provider", {
                staticClass: "formItem",
                attrs: {
                  tag: "div",
                  name: "firstName",
                  rules: "required|max:50",
                  slim: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("label", [
                          _vm._v(
                            _vm._s(_vm.$t("users.fields.firstName")) + "*"
                          ),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.firstName,
                              expression: "firstName",
                            },
                          ],
                          class: { invalid: errors.length },
                          attrs: {
                            type: "text",
                            name: "firstName",
                            autocomplete: "firstName",
                            required: "",
                          },
                          domProps: { value: _vm.firstName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.firstName = $event.target.value
                            },
                          },
                        }),
                        errors.length
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v(_vm._s(errors[0])),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("validation-provider", {
                staticClass: "formItem",
                attrs: {
                  tag: "div",
                  name: "lastName",
                  rules: "required|max:50",
                  slim: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("users.fields.lastName")) + "*"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.lastName,
                              expression: "lastName",
                            },
                          ],
                          class: { invalid: errors.length },
                          attrs: {
                            type: "text",
                            name: "lastName",
                            autocomplete: "lastName",
                            required: "",
                          },
                          domProps: { value: _vm.lastName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.lastName = $event.target.value
                            },
                          },
                        }),
                        errors.length
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v(_vm._s(errors[0])),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("validation-provider", {
                staticClass: "formItem",
                attrs: {
                  tag: "div",
                  name: "email",
                  rules: "required|email|max:255",
                  slim: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("users.fields.email")) + "*"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email",
                            },
                          ],
                          class: { invalid: errors.length },
                          attrs: {
                            type: "email",
                            name: "email",
                            autocomplete: "email",
                            required: "",
                          },
                          domProps: { value: _vm.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.email = $event.target.value
                            },
                          },
                        }),
                        errors.length
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v(_vm._s(errors[0])),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("validation-provider", {
                staticClass: "formItem",
                attrs: {
                  tag: "div",
                  name: "phone",
                  rules: "required|phone|max:20",
                  slim: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("users.fields.phone")) + "*"),
                        ]),
                        _c("the-mask", {
                          class: { invalid: errors.length },
                          attrs: {
                            type: "tel",
                            mask: "### ###-####",
                            autocomplete: "phone",
                            required: "",
                          },
                          model: {
                            value: _vm.phone,
                            callback: function ($$v) {
                              _vm.phone = $$v
                            },
                            expression: "phone",
                          },
                        }),
                        errors.length
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v(_vm._s(errors[0])),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("validation-provider", {
                ref: "addressLine",
                staticClass: "formItem",
                attrs: {
                  tag: "div",
                  name: "addressLine1",
                  rules: "required|max:50|insideQc:@province",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "editBillingInfoForm-addressLineCombobox",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("order.billing.address")) + "*"
                            ),
                          ]
                        ),
                        _c("canada-post-search", {
                          ref: "canadaPostSearch",
                          attrs: {
                            id: "editBillingInfoForm-addressLineCombobox",
                            addressCompleteSettings:
                              _vm.addressCompleteSettings,
                            invalid: errors.length > 0,
                            searchFieldId: "address",
                          },
                          on: {
                            updateAddress: _vm.updateCPAddress,
                            "ready:addressComplete":
                              _vm.handleAddressCompleteReady,
                          },
                          model: {
                            value: _vm.address.addressLine,
                            callback: function ($$v) {
                              _vm.$set(_vm.address, "addressLine", $$v)
                            },
                            expression: "address.addressLine",
                          },
                        }),
                        errors.length
                          ? _c(
                              "div",
                              { staticClass: "formItem-errorMessage error" },
                              [_vm._v(_vm._s(errors[0]))]
                            )
                          : _vm._e(),
                        _c("small", { staticClass: "hintText" }, [
                          _vm._v(
                            _vm._s(_vm.$t("users.hints.deliveryOnlyInQuebec"))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("validation-provider", {
                staticClass: "formItem",
                attrs: {
                  tag: "div",
                  name: "province",
                  mode: "aggressive",
                  hidden: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.province,
                              expression: "province",
                            },
                          ],
                          attrs: {
                            id: "editBillingInfoForm-provinceInput",
                            type: "hidden",
                          },
                          domProps: { value: _vm.province },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.province = $event.target.value
                            },
                          },
                        }),
                        errors.length
                          ? _c(
                              "div",
                              { staticClass: "formItem-errorMessage error" },
                              [_vm._v(_vm._s(errors[0]))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("validation-provider", {
                staticClass: "formItem",
                attrs: { tag: "div", name: "city", rules: "required|max:50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c(
                          "label",
                          { attrs: { for: "editBillingInfoForm-cityInput" } },
                          [_vm._v(_vm._s(_vm.$t("order.billing.city")) + "*")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.address.city,
                              expression: "address.city",
                            },
                          ],
                          class: { invalid: errors.length },
                          attrs: {
                            id: "editBillingInfoForm-cityInput",
                            type: "text",
                          },
                          domProps: { value: _vm.address.city },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.address, "city", $event.target.value)
                            },
                          },
                        }),
                        errors.length
                          ? _c(
                              "div",
                              { staticClass: "formItem-errorMessage error" },
                              [_vm._v(_vm._s(errors[0]))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("validation-provider", {
                staticClass: "formItem",
                attrs: {
                  tag: "div",
                  name: "postalCode",
                  rules: "required|max:50",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "editBillingInfoForm-postalCodeInput",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("order.billing.postalCode")) + "*"
                            ),
                          ]
                        ),
                        _c("the-mask", {
                          class: { invalid: errors.length },
                          attrs: {
                            id: "editBillingInfoForm-postalCodeInput",
                            type: "text",
                            mask: "A#A#A#",
                          },
                          model: {
                            value: _vm.address.postalCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.address, "postalCode", $$v)
                            },
                            expression: "address.postalCode",
                          },
                        }),
                        errors.length
                          ? _c(
                              "div",
                              { staticClass: "formItem-errorMessage error" },
                              [_vm._v(_vm._s(errors[0]))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("validation-provider", {
                staticClass: "formItem",
                attrs: {
                  tag: "div",
                  name: "password",
                  rules: "required|password",
                  slim: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("users.fields.password")) + "*"),
                        ]),
                        _c("password-container", {
                          attrs: { errors: errors },
                          model: {
                            value: _vm.password,
                            callback: function ($$v) {
                              _vm.password = $$v
                            },
                            expression: "password",
                          },
                        }),
                        errors.length
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v(_vm._s(errors[0])),
                            ])
                          : _vm._e(),
                        _c("ul", { staticClass: "criterias" }, [
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "users.messages.passwordCriterias.characters"
                                )
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "users.messages.passwordCriterias.length"
                                )
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "users.messages.passwordCriterias.specialCharacters"
                                )
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "users.messages.passwordCriterias.numbers"
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("validation-provider", {
                staticClass: "formItem",
                attrs: {
                  tag: "div",
                  name: "passwordConfirmation",
                  rules: "required|passwordMatch:@password",
                  slim: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c("label", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("users.fields.passwordConfirmation")
                            ) + "*"
                          ),
                        ]),
                        _c("password-container", {
                          attrs: { errors: errors },
                          model: {
                            value: _vm.passwordConfirmation,
                            callback: function ($$v) {
                              _vm.passwordConfirmation = $$v
                            },
                            expression: "passwordConfirmation",
                          },
                        }),
                        errors.length
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v(_vm._s(errors[0])),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("working-button", {
            staticClass: "btn",
            attrs: {
              type: "submit",
              isWorking: _vm.isCreatingAccount,
              label: _vm.submitLabel,
            },
          }),
        ],
        1
      ),
      _c(
        "dialog-modal",
        {
          staticClass: "wrapper_modal",
          attrs: {
            isOpen: _vm.showEmailSentModal,
            title: _vm.$t("accountEmailSentModal.title"),
          },
          on: { "close:dialog": _vm.handleCloseEmailSentDialog },
        },
        [_c("p", [_vm._v(_vm._s(_vm.$t("accountEmailSentModal.text")))])]
      ),
      _c(
        "dialog-modal",
        {
          staticClass: "wrapper_modal",
          attrs: {
            isOpen: _vm.showValidationModal,
            title: _vm.$t("accountValidationModal.title"),
          },
          on: { "close:dialog": _vm.handleCloseAccountValidationDialog },
        },
        [
          _c("account-creation-validation", {
            attrs: {
              email: _vm.email,
              firstName: _vm.firstName,
              identifier: _vm.identifier,
            },
          }),
        ],
        1
      ),
      _c(
        "dialog-modal",
        {
          staticClass: "wrapper_modal",
          attrs: {
            isOpen: _vm.registrationErrorDialogIsOpen,
            title: _vm.$t("accountRegistrationErrorModal.title"),
          },
          on: { "close:dialog": _vm.handleCloseRegistrationErrorDialog },
        },
        [
          _vm.registrationErrorType
            ? _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      `accountRegistrationErrorModal.${_vm.registrationErrorType}`
                    )
                  ),
                },
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }