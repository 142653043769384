var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "orderDetailsSection" }, [
    _c(
      "div",
      { staticClass: "bgWhite" },
      [
        _c("div", { staticClass: "orderDetails" }, [
          _c("div", { staticClass: "title" }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "10",
                  height: "11",
                  viewBox: "0 0 10 11",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z",
                    fill: "#E31F28",
                  },
                }),
              ]
            ),
            _vm._v(" " + _vm._s(_vm.title || _vm.$t("cart.title")) + " "),
          ]),
          _c(
            "div",
            { staticClass: "cartTable" },
            [
              !this.$root.getFeatureFlag("hidePrices")
                ? [
                    _c("div", { staticClass: "bottom" }, [
                      _c("div", { staticClass: "cartRow" }, [
                        _c("div", [_vm._v(_vm._s(_vm.$t("cart.subTotal")))]),
                        _c("div", [
                          _vm.subTotal !== null && _vm.shipping === null
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$root.formatPrice(_vm.subTotal))
                                ),
                              ])
                            : _vm._e(),
                          _vm.subTotal !== null &&
                          _vm.shipping !== null &&
                          _vm.shippingExtra !== null
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$root.formatPrice(
                                      _vm.subTotal -
                                        _vm.shipping -
                                        _vm.shippingExtra
                                    )
                                  )
                                ),
                              ])
                            : _vm.subTotal !== null && _vm.shipping !== null
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$root.formatPrice(
                                      _vm.subTotal - _vm.shipping
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _vm.subTotal === null && _vm.shipping === null
                            ? _c("span", [_vm._v("--")])
                            : _vm._e(),
                        ]),
                      ]),
                      _vm.shipping !== null
                        ? _c("div", { staticClass: "cartRow" }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("cart.shipping"))),
                            ]),
                            _c("div", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$root.formatPrice(_vm.shipping))
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.shippingExtra !== null && _vm.shippingExtra > 0
                        ? _c("div", { staticClass: "cartRow" }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("cart.shippingExtra"))),
                            ]),
                            _c("div", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$root.formatPrice(_vm.shippingExtra)
                                  )
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _c("hr"),
                      _c("div", { staticClass: "cartRow" }, [
                        _c("div", [_vm._v(_vm._s(_vm.$t("cart.taxes")))]),
                        _c("div", [
                          _vm.gst !== null
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("cart.gst")) +
                                    " " +
                                    _vm._s(_vm.$root.formatPrice(_vm.gst)) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v("--")]),
                        ]),
                      ]),
                      _c("div", { staticClass: "cartRow" }, [
                        _c("div"),
                        _c("div", [
                          _vm.qst !== null
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("cart.qst")) +
                                    " " +
                                    _vm._s(_vm.$root.formatPrice(_vm.qst)) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v("--")]),
                        ]),
                      ]),
                      _c("hr"),
                      _c("div", { staticClass: "cartRow orderTotal" }, [
                        _c("div", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.$t("cart.orderTotal")) + " :"),
                        ]),
                        _c("div", { staticClass: "price" }, [
                          _vm.total !== null
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$root.formatPrice(_vm.total))
                                ),
                              ])
                            : _c("span", [_vm._v("--")]),
                        ]),
                      ]),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
        _vm._t("bottom"),
        _vm._t("legalnotices"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }