export default {
  methods: {
    getHashParams() {
      if (location.hash.length) {
        return location.hash.slice(1).split('&').reduce((acc, value) => {
          const split = value.split('=');
          acc[split[0]] = split.length > 1 ? decodeURIComponent(split[1]) : null;
          return acc;
        }, {});
      }
      return {};
    },
    getListFromParam(listParameter) {
      if (!listParameter) {
        return [];
      }

      return listParameter.split(';').map(part => decodeURIComponent(part));
    },
    setHashParams(params) {
      const hashString = Object.entries(params).reduce((acc, [key, value]) => {
        value = encodeURIComponent(value);
        const entry = value === null ? key : `${key}=${value}`;
        acc = acc.length ? `${acc}&${entry}` : `#${entry}`;
        return acc;
      }, '');
      let url = location.href;
      if (location.hash.length) {
        url = url.replace(location.hash, hashString);
      } else {
        url = url + hashString;
      }
      history.pushState(null, null, url);
    },
    stringifyList(list) {
      if (!list) {
        return '';
      }

      return list.join(';');
    }
  }
};
