<template>
  <validation-observer class="editPasswordInfoForm accountForm" ref="editPasswordInfoForm" tag="form" @submit.prevent="handleSavePasswordInfo" novalidate>
    <validation-provider class="formItem" tag="div" name="currentPassword" rules="required" v-slot="{ errors }">
      <label for="editPasswordInfo-currentPasswordInput">{{ $t('users.fields.currentPassword') }}*</label>
      <password-container v-model="currentPassword" :errors="errors"></password-container>
      <div v-if="errors.length" class="formItem-errorMessage error">{{ errors[0] }}</div>
    </validation-provider>
    
    <validation-provider class="formItem" tag="div" name="newPassword" rules="required|password" v-slot="{ errors }">
      <label>{{ $t('users.fields.newPassword') }}*</label>
      <password-container v-model="newPassword" :errors="errors"></password-container>
      <div v-if="errors.length" class="formItem-errorMessage error">{{ errors[0] }}</div>
      <ul class="criterias">
        <li>{{ $t('users.messages.passwordCriterias.characters')}}</li>
        <li>{{ $t('users.messages.passwordCriterias.length')}}</li>
        <li>{{ $t('users.messages.passwordCriterias.specialCharacters')}}</li>
        <li>{{ $t('users.messages.passwordCriterias.numbers')}}</li>
      </ul>
    </validation-provider>
    
    <validation-provider class="formItem" tag="div" name="newPasswordConfirmation" rules="required|passwordMatch:@newPassword" v-slot="{ errors }">
      <label>{{ $t('users.fields.passwordConfirmation') }}*</label>
      <password-container v-model="newPasswordConfirmation" :errors="errors"></password-container>
      <div v-if="errors.length" class="formItem-errorMessage error">{{ errors[0] }}</div>
    </validation-provider>
    <div class="formItem">
      <working-button class="btn" :isWorking="passwordInfoIsSaving" :label="$t('users.actions.save')" type="submit"></working-button>
      <div class="formItem-errorMessage error" v-if="savePasswordInfoError">{{ savePasswordInfoError }}</div>
      <div class="actionResult" v-if="savePasswordInfoMessage" v-html="savePasswordInfoMessage"></div>
    </div>
  </validation-observer>
</template>

<script>
import { setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate'
import WorkingButton from './WorkingButton.vue'
import { setFocusOnFirstErrorField } from '../../helpers/domHelper'
import PasswordContainer from './PasswordContainer.vue'

setInteractionMode('eager')

export default {
  components: {
    PasswordContainer,
    ValidationObserver,
    ValidationProvider,
    WorkingButton
  },
  props: {
    memberId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      currentPassword: undefined,
      newPassword: undefined,
      newPasswordConfirmation: undefined,
      passwordInfoIsSaving: false,
      savePasswordInfoError: undefined,
      savePasswordInfoMessage: undefined
    }
  },
  methods: {
    async handleSavePasswordInfo() {
      this.savePasswordInfoError = undefined
      this.savePasswordInfoMessage = undefined

      if(!await this.$refs.editPasswordInfoForm.validate()) {
        setFocusOnFirstErrorField(this.$refs.editPasswordInfoForm.$el, '.invalid')
        return
      }

      try {
        this.passwordInfoIsSaving = true
        const request = {
          memberId: this.memberId,
          currentPassword: this.currentPassword,
          newPassword: this.newPassword
        }
        const response = await this.$axios.post('/Umbraco/Api/User/ResetPassword', request)
        this.savePasswordInfoMessage = this.$t('users.messages.success.editPasswordInfo')
        this.$emit('success:updatePassword')
      } catch (error) {
        this.savePasswordInfoError = this.$t('users.messages.SavePasswordInfoException')
      } finally {
        this.passwordInfoIsSaving = false
      }
    },
    resetForm() {
      this.currentPassword = undefined
      this.newPassword = undefined
      this.newPasswordConfirmation = undefined
      this.savePasswordInfoError = undefined
      this.savePasswordInfoMessage = undefined

      this.$nextTick(() => {
        this.$refs.editPasswordInfoForm.reset()
      })
    }
  }
}
</script>