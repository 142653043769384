var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "stockInfosSection" }, [
    _c("div", { staticClass: "stores" }, [
      _c("div", { staticClass: "store" }, [_vm._v(_vm._s(_vm.storeName))]),
    ]),
    _vm.quantity && _vm.quantity.value > 0
      ? _c("div", { staticClass: "stock valid" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("product.availability.inStockWithValue", {
                  value: _vm.quantity.value,
                })
              ) +
              " "
          ),
        ])
      : _c("div", { staticClass: "stock outStock" }, [
          _vm._v(_vm._s(_vm.$t("product.availability.notInStock"))),
        ]),
    _vm.$root.getStore() !== _vm.storeNumber
      ? _c("div", { staticClass: "changeStore" }, [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.$root.onChangeStore(_vm.storeNumber)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("chooseThisStore")))]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }