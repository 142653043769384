import productMixins from '@/productMixins';
import { mapGetters } from 'vuex';
import { pipe } from '@/functions';

export default {
  computed: {
    ...mapGetters('cartModule', ['addShippingExtra', 'cart', 'products', 'isDelivery', 'shippingExtraPrice', 'shippingPrice']),
    gst() {
      return Math.round(process.env.VUE_APP_GST_TAX * this.subTotal() + 'e+2') + 'e-2';
    },
    qst() {
      return Math.round(process.env.VUE_APP_QST_TAX * this.subTotal() + 'e+2') + 'e-2';
    }
  },
  methods: {
    addSubTotal(amount) {
      return amount += this.subTotal();
    },
    deliveryExtraFee(){
      if (this.addShippingExtra) {
        return this.shippingExtraPrice;
      }
      return 0;
    },
    deliveryFee(){
      if (this.isDelivery) {
        return this.shippingPrice;
      }
      return 0;
    },
    applyQuantity(product) {
      if (product === null) {
        return 0;
      }
      return product.basePrice * this.getProductQuantity(product.product);
    },
    applyTaxes(amount) {
      const taxes = [
        process.env.VUE_APP_GST_TAX,
        process.env.VUE_APP_QST_TAX
      ];
      let taxAmount = 0;
      taxes.forEach(tax => taxAmount += + (Math.round(tax * amount + 'e+2') + 'e-2'));
      return taxAmount;
    },
    getBasePrice(product) {
      const price = product.price
        ? product.price
        : null;

      if (!price) {
        return null;
      }

      return {
        basePrice: price.discounted !== null ? price.discounted : price.value,
        product: product,
        promotionEndDate: price.endDate !== null ? price.endDate : null
      };
    },
    getProductQuantity(product) {
      return this.isUnavailable(product) ? 0 : this.cart[product.identifier];
    },
    subTotal(cutShippingPrices) {
      if (!this.$root.hasStoreSelected) {
        return null;
      }

      const getProductSubTotal = pipe(this.getBasePrice, this.applyQuantity);
      
      let total = this.products.reduce((subTotal, product) => subTotal + getProductSubTotal(product), 0);

      if (cutShippingPrices !== true) {
        total += this.deliveryExtraFee();
        total += this.deliveryFee();
      }

      return total;
    },
    taxes() {
      return pipe(this.applyTaxes)(this.subTotal());
    },
    total() {
        return pipe(this.applyTaxes, this.addSubTotal)(this.subTotal());
    }
  },
  mixins: [productMixins]
};
