var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "summary" }, [
        _vm.isLoading
          ? _c("div", { staticClass: "loading" }, [
              _c("img", { attrs: { src: "/images/triangle-loading.gif" } }),
            ])
          : _vm._e(),
        _vm.summary
          ? _c(
              "div",
              [
                _c("h2", [
                  _vm._v(_vm._s(_vm.$t("orderConfirmation.confirmed"))),
                ]),
                _c("div", { staticClass: "row clientRow" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("orderConfirmation.client"))),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.summary.clientName))]),
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("orderConfirmation.orderNumber"))),
                    ]),
                    _c("span", [_vm._v("#" + _vm._s(_vm.orderNumber))]),
                  ]),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h3", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            `orderConfirmation.shipping.${_vm.selectedShippingMethod}.title`
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            `orderConfirmation.shipping.${_vm.selectedShippingMethod}.text`
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _vm.summary
                  ? _c("order-confirmation-products", {
                      attrs: { summary: _vm.summary },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _vm.summary
      ? _c(
          "div",
          { staticClass: "right" },
          [
            _c("div", { staticClass: "storeInfo" }, [
              _c("h4", [_vm._v(_vm._s(_vm.$t("orderConfirmation.questions")))]),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("orderConfirmation.contactUs"))),
              ]),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$t("cart.storeFormat", { store: _vm.store }))
                ),
              ]),
              _vm._m(0),
            ]),
            _c("order-details", {
              attrs: {
                gst: _vm.summary.taxes.gst,
                qst: _vm.summary.taxes.qst,
                shipping:
                  _vm.summary.shippingMethod === 0
                    ? _vm.summary.shippingAmount
                    : null,
                shippingExtra:
                  _vm.summary.shipping.extraFee !== undefined &&
                  _vm.summary.shipping.extraFee > 0
                    ? _vm.summary.shipping.extraFee
                    : null,
                "sub-total": _vm.summary.subTotal,
                total: _vm.summary.total,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "legalnotices",
                    fn: function () {
                      return [_vm._t("legalnotices")]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
            _vm._t("default"),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("span", [
        _c(
          "a",
          {
            staticClass: "mailLink",
            attrs: { href: "mailto:info@laferté.com" },
          },
          [_vm._v("info@laferté.com")]
        ),
      ]),
      _c("span", { staticClass: "phoneLink" }, [_vm._v("819 477-8950")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }