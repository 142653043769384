var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contentCart bgGrey" }, [
    _c("div", [
      !_vm.$root.getFeatureFlag("uniquePriceForAllStores") &&
      !_vm.$root.hasStoreSelected
        ? _c("div", { staticClass: "hasNoStoreSelected" }, [
            _c("div", { staticClass: "wrapper" }, [
              _c("p", [_c("strong", [_vm._v(_vm._s(_vm.$t("noPrice")))])]),
              _c("p", [_vm._v(_vm._s(_vm.$t("chooseDisplayPrice")))]),
              _c("a", { attrs: { onclick: "openSelector()" } }, [
                _vm._v(_vm._s(_vm.$t("chooseStore"))),
              ]),
            ]),
          ])
        : _c("div", { staticClass: "hasStoreSelected" }, [
            _vm.isLoading
              ? _c("div", { staticClass: "loading" }, [
                  _c("div", { staticClass: "wrapper" }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("loading")))]),
                  ]),
                ])
              : _c("div", { staticClass: "hasProducts" }, [
                  _vm.products.length
                    ? _c(
                        "div",
                        { staticClass: "mainContentProducts" },
                        [
                          _c("cart-products", {
                            attrs: {
                              "disable-cart-product": _vm.isUnavailable,
                              "get-quantity": _vm.getProductQuantity,
                              products: _vm.products,
                              "product-property-key": "identifier",
                            },
                            on: {
                              quantityChanged: _vm.onQuantityChanged,
                              deleteProduct: _vm.deleteProduct,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return [
                                      _c("promotion-end-date", {
                                        attrs: { product: props.product },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "productInfosStore" },
                                        [
                                          !_vm.isUnavailable(props.product)
                                            ? _c(
                                                "div",
                                                { staticClass: "inStock" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "product.availability.inStockWithValue",
                                                        {
                                                          value:
                                                            _vm.getQuantity(
                                                              props.product
                                                            ).value,
                                                        }
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : !_vm.isInactive(props.product) &&
                                              !_vm.isInStock(props.product)
                                            ? _c(
                                                "div",
                                                { staticClass: "notInStock" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "product.availability.notInStock"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm.isRecall(props.product)
                                            ? _c(
                                                "div",
                                                { staticClass: "unavailable" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "product.availability.recall"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "unavailable" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "product.availability.unavailable"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                          props.product.dangerousProduct ||
                                          props.product.pesticide
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "shippingUnavailable",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "product.availability.shippingUnavailable"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3177479565
                            ),
                          }),
                          _c("order-details", {
                            attrs: {
                              disabled: _vm.disabled,
                              gst: _vm.gstCartPage(),
                              qst: _vm.qstCartPage(),
                              shipping: null,
                              "sub-total": _vm.subTotal(true),
                              total: _vm.totalCartPage(),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "bottom",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "optionsSection" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              class: {
                                                btn: true,
                                                order: true,
                                                disabled: !_vm.isValidOrder,
                                              },
                                              attrs: {
                                                href: _vm.disabled
                                                  ? "javascript:window.print()"
                                                  : _vm.orderUrl,
                                                disabled: !_vm.isValidOrder,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.disabled
                                                      ? _vm.$t(
                                                          "cart.printOrder"
                                                        )
                                                      : _vm.$t("cart.order")
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    width: "10",
                                                    height: "11",
                                                    viewBox: "0 0 10 11",
                                                    fill: "none",
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z",
                                                      fill: "#E31F28",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.disabled
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "eCommerceDisabled",
                                                },
                                                [
                                                  _c("p", {
                                                    staticClass:
                                                      "warningNotice",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.$t(
                                                          "cart.eCommerceDisabled"
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "btn bordered m",
                                              attrs: {
                                                href: _vm.seasonalPageUrl,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "cart.continueShopping"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "legalnotices",
                                  fn: function () {
                                    return [_vm._t("legalnotices")]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "hasNotProducts" }, [
                        _c("div", { staticClass: "wrapper" }, [
                          _vm._m(0),
                          _c("div", { staticClass: "content" }, [
                            _c("h4", [
                              _vm._v(
                                _vm._s(
                                  _vm.$root.getFeatureFlag("ecommerce")
                                    ? _vm.$t("cart.empty")
                                    : _vm.$t("cart.emptyList")
                                )
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                  _c("div", { staticClass: "wrapper" }, [
                    _c("div", { staticClass: "cartActions" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn black",
                          attrs: { href: _vm.seasonalPageUrl },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "10",
                                height: "11",
                                viewBox: "0 0 10 11",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z",
                                  fill: "#E31F28",
                                },
                              }),
                            ]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.$t("cart.continueShopping")) + " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
          ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading",
          },
        ],
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.$root.hasStoreSelected || !_vm.products.length,
                expression: "!$root.hasStoreSelected || !products.length",
              },
            ],
          },
          [_vm._t("invalid")],
          2
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$root.hasStoreSelected && _vm.products.length,
                expression: "$root.hasStoreSelected && products.length",
              },
            ],
          },
          [_vm._t("valid")],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img" }, [
      _c("img", {
        attrs: {
          src: "/images/noImg_projects.png",
          alt: "Image par défaut",
          loading: "lazy",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }