<template>
  <div>
    <div class="imgZoomed">
      <inner-image-zoom :src="selectedImageUrl" zoomType="click" :hasSpacer="true" :zoomScale="1.2" :fullscreenOnMobile="true" :mobileBreakpoint="767" />
    </div>
    <div class="imageThumbnails">
      <div class="imageThumbnail"
           v-for="(image, imageIndex) in images" :class="{ 'selected': imageIndex === selectedImageIndex }"
           :key="imageIndex"
           @click="selectedImageIndex = imageIndex">
        <img :src="getImageUrl(image)"
             @error="onImageError(image.url)" />
      </div>
      <div class="videoContent" v-if="youtubeVideoId">
        <a :href="`https://www.youtube.com/watch?v=${youtubeVideoId}`" class="mediabox"><img :src="`https://img.youtube.com/vi/${youtubeVideoId}/sddefault.jpg`"></a>
      </div>
    </div>
  </div>
</template>
<script>
  import InnerImageZoom from 'vue-inner-image-zoom';
  import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
  export default {
    props: {
      images: {
        type: Array,
        required: true
      },
      youtubeVideoId: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        imagesInError: [],
        selectedImageIndex: ''
      };
    },
    components: {
      InnerImageZoom
    },
    mounted() {
      this.selectedImageIndex = this.images.length ? 0 : null;
    },
    methods: {
      getImageUrl(image) {
        if (!image) {
          return '/images/image-default.png';
        }
        return this.imagesInError.includes(image.url)
          ? '/images/image-default.png'
          : image.url;
      },
      onImageError(imageUrl) {
        this.imagesInError.push(imageUrl);
      }
    },
    computed: {
      selectedImageUrl() {
        return this.getImageUrl(this.images[this.selectedImageIndex]);
      }
    }
  };
</script>
