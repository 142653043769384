<template>
  <div class="orderProduct">
    <div class="img">
      <a :href="product.Url">
        <img v-if="product.images && product.images.length"
              onerror="this.src='/images/image-default.png';"
              :src="product.images[0].url"
              :alt="product.images[0].alt"
              loading="lazy"/>
        <img v-else
              src="/images/image-default.png"
              alt="Image par défaut"
              loading="lazy"/>
      </a>
    </div>
    <div class="productInfos">
      <div class="name"><span v-if="cartQuantity">{{ cartQuantity }} X </span>{{ product.name }}</div>
      <div class="sku">#{{ product.sku }}</div>
      <promotion-end-date :product="product" />
      <div class="productInfosStore">
        <div v-if="isAvailable" class="inStock">{{ $t('product.availability.inStock') }}</div>
        <div v-else-if="!isInactive && !isInStock" class="notInStock">{{ $t('product.availability.notInStock') }}</div>
        <div v-else-if="isRecall" class="unavailable">{{ $t('product.availability.recall') }}</div>
        <div v-else class="unavailable">{{ $t('product.availability.unavailable') }}</div>
        <div v-if="product.dangerousProduct || product.pesticide" class="shippingUnavailable">{{ $t('product.availability.shippingUnavailable') }}</div>
      </div>
      <div v-if="showPrice" class="price">{{ price }}</div>
    </div>
  </div>
</template>

<script>
import PromotionEndDate from '@/components/Product/PromotionEndDate.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    PromotionEndDate
  },
  props: {
    isAvailable: {
      type: Boolean,
      required: true
    },
    isInactive: {
      type: Boolean,
      required: true
    },
    isInStock: {
      type: Boolean,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    quantity: {
      type: Number,
      default: null
    },
    showPrice: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('cartModule', ['cart']),
    cartQuantity() {
      return this.cart[this.product.identifier] || this.quantity;
    },
    price() {
      if (!this.product.price) {
        return { value: 0 };
      }

      const itemPrice = this.product.price ? this.product.price : null;

      if (itemPrice) {
        return this.$root.formatPrice(this.cartQuantity * itemPrice.value);
      }
    }
  }
};
</script>
