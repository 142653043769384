<template>
  <div class="canadaPostSearchComboBox">
    <input 
      type="text" 
      :id="searchFieldId"
      :class="{ 'invalid': invalid }"
      v-model="search"
      autocomplete="section-pc"
      name="address"
    />
  </div>
</template>

<script>
  export default {
    props: {
      addressCompleteSettings: {
        type: Object,
        required: true
      },
      invalid: {
        type: Boolean,
        default: false
      },
      searchFieldId: {
        type: String,
        required: true
      },
      value: {
        required: true
      }
    },
    data() {
      return {
        hasLoaded: false,
        maxRetries: 10
      }
    },
    mounted() {
      this.initializeAddressAutocomplete()
    },
    methods: {
      initializeAddressAutocomplete() {
        this.createInitScript()
        this.createInitStyle()
        this.waitForScript()
      },
      createInitScript() {
        var scriptNode = document.createElement('script')
        scriptNode.id = 'canadapost-autocomplete'
        scriptNode.src = this.addressCompleteSettings.scriptUrl + '?key=' + this.addressCompleteSettings.apiKey
        scriptNode.type = 'text/javascript'
        document.body.appendChild(scriptNode)
      },
      createInitStyle() {
        var styleNode = document.createElement('link')
        styleNode.id = 'canadapost-style'
        styleNode.type = 'text/css'
        styleNode.rel = 'stylesheet'
        styleNode.href = this.addressCompleteSettings.styleUrl + '?key=' + this.addressCompleteSettings.apiKey
        document.head.appendChild(styleNode)
      },
      setListeners() {
        var self = this

        setTimeout(() => {
          this.setControlListeners()
          this.$emit('ready:addressComplete')
        }, 400)

        // eslint-disable-next-line no-undef
        addressComplete.listen('ready', function () {
          self.setControlListeners()
        })
      },
      setControlListeners() {
        var self = this

        // eslint-disable-next-line no-undef
        const fields = [{ element: this.searchFieldId, field: '', mode: pca.fieldMode.SEARCH }]
        const options = {
          culture: self.$i18n.locale,
          key: this.addressCompleteSettings.apiKey,
          languagePreference: self.$i18n.locale === 'en-CA' ? 'eng' : 'fra',
          suppressAutocomplete: false
        }
        // eslint-disable-next-line no-undef
        const control = new pca.Address(fields, options)
        
        if (control) {
          control.listen('populate', (address) => self.setAddress(address))
          control.listen('error', (error) => {
            // eslint-disable-next-line no-console
            console.error(error)
          })
        }

        
      },
      setAddress(address) {
        if (address) {
          this.selectedAddress = address
        }
      },
      waitForScript() {
        if (typeof addressComplete !== 'undefined') {
          this.setListeners()
        }
        else {
          if (this.maxRetries > 0) {
            this.maxRetries -= 1
            setTimeout(this.waitForScript, 250)
          } else {
            // eslint-disable-next-line no-console
            console.error('Cannot initialize AddressComplete')
          }
        }
      }
    },
    beforeDestroy() {
      var scriptNode = document.querySelector('#canadapost-autocomplete')
      var styleNode = document.querySelector('#canadapost-style')

      if (scriptNode) {
        scriptNode.remove()
      }
      if (styleNode) {
        styleNode.remove()
      }
    },
    computed: {
      selectedAddress: {
        get() {
          return this.address
        },
        set(val) {
          this.$emit('updateAddress', val)
          this.search = val.Line1
        }
      },
      search: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        }
      }
    }
  }
</script>      
