<template>
  <div class="billingAddress">
    <label for="useBillingAddress" class="checkboxContainer" v-show="!useBillingAddressDisabled">
      <input type="checkbox" id="useBillingAddress" v-model="useBillingAddress" :disabled="useBillingAddressDisabled">
      <span class="checkmark" />
      <span class="txt">{{ $t('order.billing.useBillingAddress') }}</span>
    </label>
    <template v-if="!useBillingAddress || useBillingAddressDisabled">
      <div class="row addressRow">
        <validation-provider name="address" rules="required|max:50" v-slot="{ errors }" slim>
          <div class="form-group">
            <label for="address">{{ $t('order.billing.address') }}*</label>
            <canada-post-search
              v-model.lazy="shippingAddress.address"
              :addressCompleteSettings="addressCompleteSettings"
              :invalid="errors.length > 0"
              searchFieldId="address"
              @updateAddress="updateAddress($event)"
            />
          </div>
        </validation-provider>
        <validation-provider name="city" rules="required|max:50" v-slot="{ errors }" slim>
          <div class="form-group">
            <label for="city">{{ $t('order.billing.city') }}*</label>
            <input type="text" id="city" v-model.lazy="shippingAddress.city" :class="{ invalid: errors.length }" >
          </div>
        </validation-provider>
        <validation-provider name="postalCode" rules="required|max:6|postalCode" v-slot="{ errors }" slim>
          <div class="form-group postalCodeGroup">
            <label for="postalCode">{{ $t('order.billing.postalCode') }}*</label>
            <the-mask type="text" mask="A#A#A#" v-model.lazy="shippingAddress.postalCode"  :class="{ invalid: errors.length }" />
          </div>
        </validation-provider>
      </div>
    </template>
  </div>
</template>

<script>
  import CanadaPostSearch from '@/components/CanadaPostSearch';
  import ShippingMethod from '@/models/store/ShippingMethod';
  import { debounce } from 'lodash';
  import { mapActions, mapState } from 'vuex';
  import { TheMask } from 'vue-the-mask';
  import { ValidationProvider } from 'vee-validate';

  export default {
    components: {
      CanadaPostSearch,
      TheMask,
      ValidationProvider
    },
    props: {
      addressCompleteSettings: {
        type: Object,
        required: true
      },
      user: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        shippingAddress: {
          address: '',
          city: '',
          postalCode: ''
        },
        ShippingMethod,
        useBillingAddress: false
      };
    },
    methods: {
      ...mapActions('cartModule', ['getShippingCost', 'setShippingAddress', 'setIsUsingBillingAddress']),
      async calculateShippingCost() {
        await this.getShippingCost(this.$root.getStore());
      },
      updateAddress(address) {
        this.shippingAddress = { address: address.Line1, city: address.City, postalCode: address.PostalCode };
      },
      updateUserAddress() {
        const storedAddress = this.$store.state.cartModule.shippingAddress;
        const existingAddress = this.user ? this.user.addresses.find(x => x.addressID === this.user.defaultShippingAddressID) : null;

        this.shippingAddress.address = storedAddress && storedAddress.address ? storedAddress.address : (existingAddress ? existingAddress.addressLine : '');
        this.shippingAddress.city = storedAddress && storedAddress.city ? storedAddress.city : (existingAddress ? existingAddress.city : '');
        this.shippingAddress.postalCode = storedAddress && storedAddress.postalCode ? storedAddress.postalCode : (existingAddress ? existingAddress.postalCode : '');
      }
    },
    mounted() {
      const storedAddress = this.$store.state.cartModule.shippingAddress;

      this.updateUserAddress();

      if (this.$store.state.cartModule.useShippingAddress !== undefined) {
        this.useShippingAddress = this.$store.state.cartModule.useShippingAddress;
      }
    },
    computed: {
      ...mapState('cartModule', ['billingAddress', 'shippingMethod']),
      useBillingAddressDisabled() {
        return this.shippingMethod === ShippingMethod.Pickup;
      }
    },
    watch: {
      billingAddress(value) {
        if (this.useBillingAddress && !this.useBillingAddressDisabled){
          this.shippingAddress = { ...value };
        }
      },
      shippingAddress: {
        deep: true,
        handler: debounce(async function(value) {
          this.setShippingAddress(value);
          await this.calculateShippingCost();
        }, 700)
      },
      useBillingAddress(value) {
        this.setIsUsingBillingAddress(value);

        if (value === true) {
          this.shippingAddress = { ...this.billingAddress };
        }
      }
    }
  };
</script>
