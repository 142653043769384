<template>
  <div>
    <div class="radioButtonFacet desktop" v-if="!isHidden">
      <label class="facetTitle">
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" fill="#E31F28" />
        </svg>
        {{ header }}
      </label>
      <div>
        <div :class="['facetItem', { selected: selectedFacets.includes(facet.value) }]"
             v-for="(facet, index) in facets"
             :key="index">
          <div class="choiceFacet">
            <span @click="toggleFacet(facet.value)">
              {{ useI18nResources ? $t(`${i18nNamespace}.${facet.value}`) : getFacetText(facet.value) }}
            </span>
            <span v-if="showCount && facet.count">({{ facet.count }})</span>
          </div>
        </div>
        <div v-if="allText" :class="['facetItem', 'all', { selected: selectedFacets.length === 0 }]">
          <span @click="resetFacets">{{ allText }}</span>
        </div>
      </div>
    </div>
    <div class="dropdownFacet mobile" v-if="!isHidden">
      <label class="facetTitle">{{ header }}</label>
      <div>
        <div class="activator" @click="isDropdownOpen = !isDropdownOpen">
          <span>{{ dropdownValues }}</span>
        </div>
        <div class="facets" v-show="isDropdownOpen">
          <div v-if="allText" :class="['facetItem', 'all']">
            <span @click="resetFacets">{{ allText }}</span>
          </div>
          <div class="facetItem"
              v-for="(facet, index) in facets"
              :key="index">
            <checkbox
              v-model="selectedFacets"
              :checkedValue="facet.value"
              @input="refreshSelectedFacets"
            />
            <span @click="toggleFacet(facet.value)">
              {{ useI18nResources ? $t(`${i18nNamespace}.${facet.Value}`) : facet.value }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue';
import hashMixins from '@/hashMixins';

export default {
  components: {
    Checkbox
  },
  props: {
    hideIfFilterCountLessThan: {
      type: [Boolean,Number],
      default: false
    },
    itemsContainer: {
      type: Object,
      required: true
    },
    facetProperty: {
      type: String,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    showCount: {
      type: Boolean,
      default: false
    },
    allText: {
      type: String,
      default: null
    },
    emptyText: {
      type: String,
      default: null
    },
    requestFacetProperty: {
      type: String,
      required: true
    },
    i18nNamespace: {
      type: String,
      default: null
    },
    useI18nResources: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      facets: [],
      isDropdownOpen: false,
      selectedDropdownFacet: [],
      selectedFacets: []
    };
  },
  created() {
    const hashParams = this.getHashParams();
    this.selectedFacets = this.getListFromParam(hashParams[this.requestFacetProperty]);
    this.facets = this.selectedFacets.map(facet => ({
      name: this.facetProperty,
      value: facet,
      count: null
    }));
    this.refreshSelectedFacets();
  },
  computed: {
    dropdownValues() {
      const facets = this.useI18nResources
        ? this.selectedFacets.map(facet => this.$t(`${this.i18nNamespace}.${facet}`))
        : this.selectedFacets;

      if (!facets.length) {
        return this.allText ? this.allText : this.emptyText;
      } else {
        return facets.join(', ');
      }
    },
    isHidden() {
      return Number.isInteger(this.hideIfFilterCountLessThan)
        ? this.facets.length < this.hideIfFilterCountLessThan && !this.selectedFacets.length
        : !!this.hideIfFilterCountLessThan;
    }
  },
  methods: {
    getFacetText(facetText) {
      if (!facetText) {
        return this.$i18n.t('facets.others');
      }

      return facetText;
    },
    toggleFacet(facet) {
      if (this.selectedFacets.indexOf(facet) === -1) {
        this.selectedFacets = [ ...this.selectedFacets, facet ];
      } else {
        this.selectedFacets = this.selectedFacets.filter(x => x !== facet);
      }
      this.refreshSelectedFacets();
    },
    refreshFacets() {
      this.facets = this.itemsContainer.facets
        .filter(x => x.name === this.facetProperty)
        .map(x => {
          const { value, count } = x;
          return { value, count };
        });
    },
    refreshSelectedFacets() {
      this.itemsContainer.selectedFacets = {
        ...this.itemsContainer.selectedFacets,
        [this.requestFacetProperty]: this.selectedFacets
      };
    },
    resetFacets() {
      this.selectedFacets = [];
      this.refreshSelectedFacets();

      this.isDropdownOpen = false;
    }
  },
  watch: {
    'itemsContainer.items'() {
      this.refreshFacets();
      this.$root.$emit('scroll');
    }
  },
  mixins: [hashMixins]
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}
</style>
