<template>
  <div class="contentCart bgGrey">
    <div>
      <div v-if="!$root.getFeatureFlag('uniquePriceForAllStores') && !$root.hasStoreSelected" class="hasNoStoreSelected">
        <div class="wrapper">
          <p><strong>{{ $t('noPrice') }}</strong></p>
          <p>{{ $t('chooseDisplayPrice') }}</p>
          <a onclick="openSelector()">{{ $t('chooseStore') }}</a>
        </div>
      </div>
      <div v-else class="hasStoreSelected">
        <div v-if="isLoading" class="loading">
          <div class="wrapper">
            <p>{{ $t('loading') }}</p>
          </div>
        </div>
        <div v-else class="hasProducts">
          <div v-if="products.length" class="mainContentProducts">
            <cart-products :disable-cart-product="isUnavailable"
                           :get-quantity="getProductQuantity"
                           :products="products"
                           product-property-key="identifier"
                           @quantityChanged="onQuantityChanged"
                           @deleteProduct="deleteProduct">
              <template v-slot="props" name="product">
                <promotion-end-date :product="props.product" />
                <div class="productInfosStore">
                  <div v-if="!isUnavailable(props.product)" class="inStock">{{ $t('product.availability.inStockWithValue', { value: getQuantity(props.product).value }) }}</div>
                  <div v-else-if="!isInactive(props.product) && !isInStock(props.product)" class="notInStock">{{ $t('product.availability.notInStock') }}</div>
                  <div v-else-if="isRecall(props.product)" class="unavailable">{{ $t('product.availability.recall') }}</div>
                  <div v-else class="unavailable">{{ $t('product.availability.unavailable') }}</div>
                  <div v-if="props.product.dangerousProduct || props.product.pesticide" class="shippingUnavailable">{{ $t('product.availability.shippingUnavailable') }}</div>
                </div>
              </template>
            </cart-products>
            <order-details
              :disabled="disabled"
              :gst="gstCartPage()"
              :qst="qstCartPage()"
              :shipping="null"
              :sub-total="subTotal(true)"
              :total="totalCartPage()"
            >
              <template v-slot:bottom>
                <div class="optionsSection">
                  <a :class="{'btn': true, 'order': true, 'disabled': !isValidOrder }" :href="disabled ? 'javascript:window.print()' : orderUrl" :disabled="!isValidOrder">
                    {{ disabled ? $t('cart.printOrder') : $t('cart.order') }}
                    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" fill="#E31F28" />
                    </svg>
                  </a>
                  <div v-if="disabled" class="eCommerceDisabled">
                    <p class="warningNotice" v-html="$t('cart.eCommerceDisabled')" />
                  </div>
                  <a :href="seasonalPageUrl" class="btn bordered m">{{ $t('cart.continueShopping') }}</a>
                </div>
              </template>
              <template v-slot:legalnotices>
                <slot name="legalnotices" />
              </template>
            </order-details>
          </div>
          <div v-else class="hasNotProducts">
            <div class="wrapper">
              <div class="img">
                <img src="/images/noImg_projects.png" alt="Image par défaut" loading="lazy"/>
              </div>
              <div class="content">
                <h4>{{ $root.getFeatureFlag('ecommerce') ? $t('cart.empty') : $t('cart.emptyList') }}</h4>
              </div>
            </div>
          </div>
          <div class="wrapper">
            <div class="cartActions">
              <a :href="seasonalPageUrl" class="btn black">
                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" fill="#E31F28" />
                </svg>
                {{ $t('cart.continueShopping') }}
              </a>
              <!--<a :href="disabled ? 'javascript:window.print()' : orderUrl" class="btn">{{ disabled ? $t('cart.printOrder') : $t('cart.order') }}</a>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!isLoading">
      <div v-show="!$root.hasStoreSelected || !products.length">
        <slot name="invalid" />
      </div>
      <div v-show="$root.hasStoreSelected && products.length">
        <slot name="valid" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import productStatus from '@/models/product/Status';
import CartProducts from '@/components/Cart/CartProducts.vue';
import OrderDetails from '@/components/Cart/OrderDetails.vue';
import orderMixins from '@/orderMixins';
import productMixins from '@/productMixins';
import PromotionEndDate from '@/components/Product/PromotionEndDate.vue';

export default {
  components: {
    CartProducts,
    OrderDetails,
    PromotionEndDate
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    orderUrl: {
      type: String,
      required: true
    },
    productsPageUrl: {
      type: String,
      required: true
    },
    seasonalPageUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      productStatus,
      hasProductsBeenRemovedFromCart: false
    };
  },
  async mounted() {
    const productIds = Object.keys(this.cart);
    await this.$store.dispatch('cartModule/setProducts', productIds);
    for (const product of productIds) {
      if (!this.products.some(x => x.identifier.includes(product))) {
        this.deleteProduct(product);
        this.hasProductsBeenRemovedFromCart = true;
      }
    }
    if (this.hasProductsBeenRemovedFromCart === true) {
      this.$tostini({ message: this.$t('product.availability.removedFromCart'), duration: 5000 });
      this.hasProductsBeenRemovedFromCart = false;
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions('cartModule', ['removeProduct']),
    deleteProduct(productId) {
      this.removeProduct(productId);
    },
    gstCartPage() {
      return Math.round(process.env.VUE_APP_GST_TAX * this.subTotal(true) * 100) / 100;
    },
    qstCartPage() {
      return Math.round(process.env.VUE_APP_QST_TAX * this.subTotal(true) * 100) / 100;
    },
    totalCartPage() {
      return this.subTotal(true) + this.gstCartPage() + this.qstCartPage();
    },
    getProductQuantity(product) {
      return this.isUnavailable(product) ? 0 : this.cart[product.identifier];
    },
    async onQuantityChanged(value, productId) {
      await this.$store.dispatch('cartModule/setQuantity', {
        productId: productId,
        quantity: value
      });
    },
    sendViewCartGaEvent() {
      try {
        if (dataLayer && this.products) {
          dataLayer.push({
            event: 'view_cart',
            ecommerce: {
              items: this.products.map(item => ({
                item_id: item.sku,
                item_name: item.name,
                item_brand: item.brand,
                item_category: item.majorCategory,
                item_category2: item.intermediateCategory,
                item_category3: item.minorCategory
              }))
            }
          });
        }
      } catch { /*Fait rien*/ }
    }
  },
  computed: {
    ...mapGetters('cartModule', ['cart', 'products']),
    ...mapGetters('globalModule', ['featureFlags']),
    ...mapState('cartModule', ['shippingMethod', 'shippingPrice']),
    isValidOrder() {
      const hasStoreAndItems = this.$root.hasStoreSelected && this.total() > 0;

      // eslint-disable-next-line no-undef
      return hasStoreAndItems && (!this.shippingDisabled || this.shippingMethod === shippingMethods.Pickup);
    },
    shippingDisabled() {
      return this.products.find(x => x.dangerousProduct || x.pesticide) != null;
    }
  },
  watch: {
    products() {
      if (this.products) {
        this.sendViewCartGaEvent();
      }
    }
  },
  mixins: [orderMixins, productMixins]
};
</script>
