<template>
  <div class="cartProducts">
    <div class="wrapper">
      <p>{{ $t('cart.orderAtTheStore') }} <b>{{ $root.selectedStoreName }}</b> <a class="changeStoreLink" onclick="openSelector()">{{ $t('changeStore') }}</a></p>
      <div class="cartProductsHeader">
        <div class="label product">
          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 5.49102L9.53674e-07 0.5L1.07292e-06 10.5L10 5.49102Z" fill="#E31F28" />
          </svg>
          {{ $t('cart.product') }} ({{ count }})
        </div>
        <div class="label unityPrice">{{ $t('cart.priceUnity') }}</div>
        <div class="label quantity">{{ $t('cart.quantity') }}</div>
        <div class="label totalPrice">{{ $t('cart.price') }}</div>
      </div>
      <div class="cartProductsContent">
        <cart-product v-for="product in products"
                      :disable-cart-product="disableCartProduct"
                      :getQuantity="getQuantity"
                      :key="product.Identifier"
                      :product="product"
                      @deleteProduct="deleteProduct"
                      @quantityChanged="onQuantityChanged">
          <slot :product="product" />
        </cart-product>
      </div>
    </div>
  </div>
</template>

<script>
import CartProduct from '@/components/Cart/CartProduct.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    CartProduct
  },
  props: {
    disableCartProduct: {
      type: Function,
      required: true
    },
    getQuantity: {
      type: Function,
      required: true
    },
    products: {
      type: Array,
      required: true
    },
    productPropertyKey: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('cartModule', ['count'])
  },
  methods: {
    deleteProduct(productId) {
      this.$emit('deleteProduct', productId);
    },
    onQuantityChanged(value, productId) {
      this.$emit('quantityChanged', value, productId);
    },
    openSelector() {
      const branchesContentChoice = document.getElementById('branchesContentChoice');
      if (branchesContentChoice) {
        branchesContentChoice.classList.add('open');
        branchesContentChoice.style.height = branchesContentChoice.scrollHeight + 'px';
        window.scrollTo(0, 0);
      } else {
        throw new Error('No element with ID: \'branchesContentChoice\' found in DOM.');
      }
    }
  }
};
</script>
