var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "validation-observer",
    {
      ref: "editUserInfoForm",
      staticClass: "editUserInfoForm accountForm",
      attrs: { tag: "form" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.handleSaveUserInfo.apply(null, arguments)
        },
      },
    },
    [
      _c("validation-provider", {
        staticClass: "formItem",
        attrs: { tag: "div", name: "firstName", rules: "required|max:50" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c("label", { attrs: { for: "editUserInfo-firstNameInput" } }, [
                  _vm._v(_vm._s(_vm.$t("users.fields.firstName")) + "*"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.contact.firstName,
                      expression: "contact.firstName",
                    },
                  ],
                  class: { invalid: errors.length },
                  attrs: { id: "editUserInfo-firstNameInput", type: "text" },
                  domProps: { value: _vm.contact.firstName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.contact, "firstName", $event.target.value)
                    },
                  },
                }),
                errors.length
                  ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                      _vm._v(_vm._s(errors[0])),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("validation-provider", {
        staticClass: "formItem",
        attrs: { tag: "div", name: "lastName", rules: "required|max:50" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c("label", { attrs: { for: "editUserInfo-lastNameInput" } }, [
                  _vm._v(_vm._s(_vm.$t("users.fields.lastName")) + "*"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.contact.lastName,
                      expression: "contact.lastName",
                    },
                  ],
                  class: { invalid: errors.length },
                  attrs: { id: "editUserInfo-lastNameInput", type: "text" },
                  domProps: { value: _vm.contact.lastName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.contact, "lastName", $event.target.value)
                    },
                  },
                }),
                errors.length
                  ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                      _vm._v(_vm._s(errors[0])),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("validation-provider", {
        staticClass: "formItem",
        attrs: { tag: "div", name: "phone", rules: "phone|max:20" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c("label", { attrs: { for: "editUserInfo-phoneInput" } }, [
                  _vm._v(_vm._s(_vm.$t("users.fields.phone")) + "*"),
                ]),
                _c("the-mask", {
                  class: { invalid: errors.length },
                  attrs: {
                    id: "editUserInfo-phoneInput",
                    type: "tel",
                    mask: "(###) ###-####",
                  },
                  model: {
                    value: _vm.contact.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.contact, "phone", $$v)
                    },
                    expression: "contact.phone",
                  },
                }),
                errors.length
                  ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                      _vm._v(_vm._s(errors[0])),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "formItem" },
        [
          _c("working-button", {
            staticClass: "btn",
            attrs: {
              isWorking: _vm.userInfoIsSaving,
              label: _vm.$t("users.actions.save"),
              type: "submit",
            },
          }),
          _vm.saveUserInfoError
            ? _c("div", { staticClass: "formItem-errorMessage error" }, [
                _vm._v(_vm._s(_vm.saveUserInfoError)),
              ])
            : _vm._e(),
          _vm.saveUserInfoMessage
            ? _c("div", { staticClass: "actionResult" }, [
                _vm._v(_vm._s(_vm.saveUserInfoMessage)),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }